import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import NewsListing from '../components/NewsListing';
import { getCollectionLocaleFields, translateString } from '../utils';

const NewsArchiveTemplate = ({
  data: { datoCmsNewsArchive, latestPost, otherPosts },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slugLocales, title } = datoCmsNewsArchive;

  latestPost = getCollectionLocaleFields(latestPost, locale);

  otherPosts = getCollectionLocaleFields(otherPosts, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsNewsArchive}
      verticalText={translateString('News', locale)}
    >
      <main>
        {latestPost.nodes.length > 0 ? (
          <Banner
            overline={translateString('Latest Post', locale)}
            heading={latestPost.nodes[0].title}
            link={{
              page: latestPost.nodes[0],
              text: translateString('Read post', locale),
            }}
            image={latestPost.nodes[0].bannerImage}
            locale={locale}
            hiddenHeading={title}
          />
        ) : (
          <Banner heading={title} locale={locale} />
        )}
        <NewsListing items={otherPosts.nodes} locale={locale} />
      </main>
    </Layout>
  );
};

export const NewsArchiveTemplateQuery = graphql`
  query NewsArchiveTemplateQuery($id: String!) {
    datoCmsNewsArchive(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      ...LinkFragment
    }
    latestPost: allDatoCmsNews(
      filter: { slug: { ne: null } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      group(field: locale, limit: 1) {
        fieldValue
        nodes {
          ...NewsCardFragment
          bannerImage: featuredImage {
            ...SubpageBannerImageFragment
          }
          ...LinkFragment
        }
      }
    }
    otherPosts: allDatoCmsNews(
      filter: { slug: { ne: null } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      group(field: locale, skip: 1) {
        fieldValue
        nodes {
          ...NewsCardFragment
        }
      }
    }
  }
`;

export default NewsArchiveTemplate;
