import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fluidFontSize,
  standardTransition,
} from '../styles';
import { InViewAnimation, Overline, Link } from './ui';
import { buildUrl } from '../utils';

const StyledNewsCard = styled.article`
  position: relative;
  cursor: pointer;
`;

const StyledInner = styled.div`
  transition: ${standardTransition('box-shadow')};

  &:hover {
    box-shadow: 0px 0px 20px 0px ${brandColours.denary};
  }
`;

const StyledImage = styled(Img)`
  ${maxBreakpointQuery.smedium`
    max-height: 400px;
  `}
`;

const StyledContent = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.tiny`
    right: 20px;
  `}

  ${minBreakpointQuery.small`
    right: 40px;
  `}

  ${minBreakpointQuery.medium`
    right: 80px;
    padding: 40px;
  `}

  ${minBreakpointQuery.large`
    right: 120px;
    padding: 50px;
  `}
`;

const StyledOverline = styled(Overline)`
  padding-left: 0;

  &:before {
    content: none;
  }
`;

const StyledHeading = styled.h3`
  ${fluidFontSize(
    '20px',
    '28px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const NewsCard = ({
  overline,
  heading,
  link,
  image: { fluid, alt },
  ...props
}) => (
  <StyledNewsCard
    {...props}
    onClick={() => {
      navigate(buildUrl(link.slug, link));
    }}
  >
    <InViewAnimation>
      <StyledInner>
        <StyledImage fluid={fluid} alt={alt} />
        <StyledContent>
          <StyledOverline>{overline}</StyledOverline>
          <StyledHeading>
            <Link to={link}>{heading}</Link>
          </StyledHeading>
        </StyledContent>
      </StyledInner>
    </InViewAnimation>
  </StyledNewsCard>
);

export default NewsCard;

export const NewsCardFragment = graphql`
  fragment NewsCardFragment on DatoCmsNews {
    id
    title
    locale
    slug
    category {
      name
    }
    featuredImage {
      fluid(
        maxHeight: 600
        maxWidth: 700
        imgixParams: { auto: "compress", fit: "crop", h: "600", w: "700" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
    internal {
      type
    }
  }
`;
