import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins, headingStyles } from '../styles';
import { Container } from './ui';
import NewsCard from './NewsCard';
import { translateString } from '../utils';

const StyledNewsListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  ${minBreakpointQuery.tsmall`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledHeading = styled.h2`
  margin-bottom: 20px;
  ${headingStyles('25px', '35px')};

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-bottom: 50px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.small`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.large`
    row-gap: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    row-gap: 70px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    row-gap: 90px;
  `}
`;

const NewsListing = ({ items, locale }) =>
  items.length > 0 && (
    <StyledNewsListing>
      <Container>
        <StyledHeader>
          <StyledHeading>
            {translateString('All Recent News', locale)}
          </StyledHeading>
        </StyledHeader>
        <StyledInner>
          {items.map(item => (
            <NewsCard
              key={item.id}
              overline={item.category.name}
              heading={item.title}
              link={item}
              image={item.featuredImage}
            />
          ))}
        </StyledInner>
      </Container>
    </StyledNewsListing>
  );

export default NewsListing;
